
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    radioList: {
      default: () => [],
      type: Array
    }
  },
  emits: ['change'],
  setup (props, context) {
    const radio = ref(0)
    const toggle = (val: string) => {
      context.emit('change', val)
    }
    return {
      radio,
      toggle
    }
  }
})
