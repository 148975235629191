
import { defineComponent, PropType, ref } from 'vue'
import { IareaList, IlabelList } from '@/types/policyList/index'
import { Idic } from '@/types/bidList/index'

export default defineComponent({
  props: {
    datalist: {
      type: Array as PropType<IareaList[] | IlabelList[] | Idic[] | null>
    },
    // 默认选中
    basePickUpItem: {
      type: String
    },
    // 使用的key-value值
    keyValue: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  emits: ['toggle'],
  setup (props, context) {
    const showDrop = ref(false) // 当前是否显示下拉框
    const pickUpItem = ref<string>('') // 当前选中项
    const refIcon = ref<null | HTMLElement>(null)
    const isShowDrop = (cb: boolean) => {
      showDrop.value = cb
      if (cb === true && refIcon.value) {
        refIcon.value.style.transform = 'rotate(-180deg)'
        refIcon.value.style.transition = 'all 0.3s ease'
      } else if (cb === false && refIcon.value) {
        refIcon.value.style.transform = 'rotate(0)'
        refIcon.value.style.transition = 'all 0.3s ease'
      }
    }
    const handleCommand = (command: IareaList | IlabelList | Idic) => {
      pickUpItem.value = command[props.value]
      context.emit('toggle', command)
    }
    return {
      showDrop,
      isShowDrop,
      refIcon,
      pickUpItem,
      handleCommand
    }
  }
})

