<template>
  <div id="radio">
    <el-radio-group v-model="radio">
      <el-radio
        v-for="(item, index) in radioList"
        :key="index"
        :label="item.key"
        @change="toggle"
        >{{ item.title }}</el-radio
      >
    </el-radio-group>
    <div class="border_line" />
  </div>
</template>
<script lang='ts'>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    radioList: {
      default: () => [],
      type: Array
    }
  },
  emits: ['change'],
  setup (props, context) {
    const radio = ref(0)
    const toggle = (val: string) => {
      context.emit('change', val)
    }
    return {
      radio,
      toggle
    }
  }
})
</script>
<style lang='scss' scoped>
#radio {
  margin-top: 35px;
  :deep(.el-radio__inner) {
    border-radius: 2px;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner:after) {
    transform: rotate(45deg) scaleY(1);
  }
  :deep(.el-radio__inner::after){
    box-sizing: content-box;
    content: '';
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform 0.15s ease-in 0.05s;
    transform-origin: center;
    background-color: transparent;
    border-radius: 0;
  }
  .border_line {
    margin-top: 32px;
    width: 100%;
    height: 1px;
    background: #f1f1f1;
  }
}
</style>
