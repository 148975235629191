
import { defineComponent } from 'vue'
import BidListHeadContentLatest from './BidListContentLatest.vue'
import BidListHeadContentAll from './BidListContentAll.vue'

export default defineComponent({
  components: {
    BidListHeadContentLatest,
    BidListHeadContentAll
  }
})
