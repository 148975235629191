<template>
  <div class="latestBid">
    <ul class="latestBidHead flex">
      <li
        v-for="(item, index) in latest"
        :key="index"
        :class="{
          active: index === defaultIndex,
          pointer: index !== defaultIndex
        }"
        @click="defaultIndex = index"
      >
        {{ item }}
      </li>
      <div class="line" ref="line"></div>
    </ul>
    <BidItem :bidList="BidList" :dropDownTitle="dropDownTitle" />
  </div>
</template>
<script lang='ts'>
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue'
import BidItem from '@/common/BidItem.vue'
import { latestBid } from '@/api/bidList'
import { IbidItem } from '@/types/bidList'
import { emitter } from '@/mitt/index'

export default defineComponent({
  components: {
    BidItem
  },
  setup () {
    const latest = ref(['最新招标信息', '最新中标信息'])
    const defaultIndex = ref(0)
    const line = ref<null | HTMLElement>(null)
    const bidList = ref<IbidItem[]>([])
    const _bidList = ref<IbidItem[]>([])
    const BidList = ref<IbidItem[]>([])
    const dropDownTitle = ref('')
    watch(defaultIndex, (newV, oldV) => {
      if (newV !== oldV && newV === 1 && line.value) {
        line.value.style.transform = 'translateX(149px)'
        line.value.style.transition = 'all 0.3s ease'
        dropDownTitle.value = '中标公示'
        BidList.value = bidList.value
      } else if (newV !== oldV && newV === 0 && line.value) {
        line.value.style.transform = 'translateX(0)'
        line.value.style.transition = 'all 0.3s ease'
        dropDownTitle.value = '招标公示'
        BidList.value = _bidList.value
      }
    })
    watchEffect(() => {
      if (defaultIndex.value === 0) {
        dropDownTitle.value = '招标公示'
        BidList.value = bidList.value
      } else {
        dropDownTitle.value = '中标公示'
        BidList.value = _bidList.value
      }
    })
    const params = (type: string) => {
      return {
        keyword: '',
        source: '',
        region: '',
        classCode: type,
        releaseDateType: '',
        sort: '',
        paginate: { currentPageNum: 1, recordsPerPage: 6 }
      }
    }
    const getLatestBid = () => {
      latestBid(params('1001')).then(res => {
        bidList.value = res.data.rows
        emitter.emit('bidList', res.data)
      }).then(() => {
        latestBid(params('1002')).then(res => {
          _bidList.value = res.data.rows
        })
      })
    }
    onMounted(() => {
      getLatestBid()
    })
    return {
      latest,
      defaultIndex,
      line,
      bidList,
      dropDownTitle,
      BidList
    }
  }
})
</script>
<style lang='scss' scoped>
.latestBid {
  .latestBidHead {
    border-bottom: 1px solid #f1f1f1;
    position: relative;
    li {
      padding: 0 26px 16px 26px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
    }
    .active {
      color: #3c89ff;
    }
    .line {
      position: absolute;
      width: 149px;
      height: 3px;
      background: #3c89ff;
      top: 36px;
    }
  }
}
</style>
