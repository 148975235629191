export const dictioary = {
  areaList: [
    { title: '全部区域', key: '' },
    { title: '深圳市', key: '4403' },
    { title: '宝安区', key: '440306' },
    { title: '龙岗区', key: '440307' },
    { title: '罗湖区', key: '440303' },
    { title: '南山区', key: '440305' },
    { title: '福田区', key: '440304' },
    { title: '盐田区', key: '440308' },
    { title: '龙华区', key: '440311' },
    { title: '光明区', key: '440310' },
    { title: '坪山新区', key: '440309' },
    { title: '大鹏新区', key: '440312' }
  ],
  industryList: [
    { key: '', title: '全部' },
    { key: '货物', title: '货物' },
    { key: '服务', title: '服务' },
    { key: '工程', title: '工程' },
    { key: '其他', title: '其他' }
  ],
  typeList: [
    { key: '', title: '全部' },
    { key: '1001', title: '招标公示' },
    { key: '1002', title: '中标公示' },
    { key: '1003', title: '变更公示' },
    { key: '1004', title: '拟在建' },
    { key: '1005', title: '其他公示结果' }
  ],
  releaseTimeList: [
    { key: '', title: '不限' },
    { key: '1', title: '3日内' },
    { key: '2', title: '7日内' },
    { key: '3', title: '1个月内' },
    { key: '4', title: '3个月内' }
  ],
  tenderPublicList: [
    { key: '1001', title: '全部' },
    { key: '2001', title: '公开招标' },
    { key: '2004', title: '单一来源' },
    { key: '2003', title: '竞争性谈判' },
    { key: '2002', title: '邀请招标' },
    { key: '2005', title: '询价' }
  ]
}
