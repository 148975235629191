
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue'
import BaseDropDown from '@/common/BaseDropDown.vue'
import BaseRadio from '@/common/BaseRadio.vue'
import pagination from '@/common/pagination.vue'
import BidItem from '@/common/BidItem.vue'
import { dictioary } from '@/utils/dictionary'
import { Idic, IbidItem } from '@/types/bidList/index'
import { latestBid } from '@/api/bidList'
import { emitter } from '@/mitt/index'
interface Ipageination {
  total?: number
  currentPage: number
  pageSize: number
}

export default defineComponent({
  components: {
    BaseDropDown,
    BidItem,
    pagination,
    BaseRadio
  },
  setup() {
    const line = ref<null | HTMLElement>(null)
    const RefType = ref<null | HTMLElement>(null)
    const bidList = ref<IbidItem[]>([])
    // 全部招投标
    const allList = ref(['全部招标信息', '全部中标信息'])
    const defaultIndex = ref(0)
    const dropDownTitle = ref('')
    const areaList = ref<Idic[]>([])
    const region = ref('')
    const industryList = ref<Idic[]>([])
    const sort = ref('')
    const typeList = ref<Idic[]>([])
    const classCode = ref('')
    const releaseTimeList = ref<Idic[]>([])
    const releaseDateType = ref('')
    // 单选框
    const radioList = ref<Idic[]>([])
    // 分页
    const pageSize = ref(6)
    const page = ref(1)
    const total = ref(0)
    const RefAll = ref<null | HTMLElement>(null)
    const scrollH = ref(0)
    const params = () => {
      return {
        keyword: '',
        source: '',
        region: region.value,
        releaseDateType: releaseDateType.value,
        sort: sort.value,
        classCode: classCode.value,
        paginate: {
          currentPageNum: String(page.value),
          recordsPerPage: String(pageSize.value)
        }
      }
    }
    const getLatestBid = () => {
      latestBid(params()).then((res) => {
        bidList.value = res.data.rows
        total.value = res.data.total
      })
    }
    const search = (value: Ipageination) => {
      page.value = value.currentPage
      pageSize.value = value.pageSize
      getLatestBid()
      window.scrollTo(0, scrollH.value)
    }
    const parentCb = (el: HTMLElement, height: number): void | number => {
      if (el.offsetParent) {
        height += el.offsetTop
        return parentCb(el.offsetParent as HTMLElement, height)
      } else {
        return height + 450
      }
    }
    const change = (key: string) => {
      classCode.value = key
      getLatestBid()
    }
    watch(defaultIndex, (newV) => {
      if (newV === 1 && line.value) {
        line.value.style.transform = 'translateX(149px)'
        line.value.style.transition = 'all 0.3s ease'
        dropDownTitle.value = '中标公示'
      } else if (newV === 0 && line.value) {
        line.value.style.transform = 'translateX(0)'
        line.value.style.transition = 'all 0.3s ease'
        dropDownTitle.value = '招标公示'
      }
    })
    watch(dropDownTitle, (newV) => {
      if (newV === '招标公示' && line.value) {
        line.value.style.transform = 'translateX(0)'
        line.value.style.transition = 'all 0.3s ease'
        defaultIndex.value = 0
        ;(RefType.value as any).pickUpItem = '招标公示'
        classCode.value = '1001'
        getLatestBid()
      } else if (newV === '中标公示' && line.value) {
        line.value.style.transform = 'translateX(149px)'
        line.value.style.transition = 'all 0.3s ease'
        defaultIndex.value = 1
        ;(RefType.value as any).pickUpItem = '中标公示'
        classCode.value = '1002'
        getLatestBid()
      }
    })
    watchEffect(() => {
      if (defaultIndex.value === 0) {
        dropDownTitle.value = '招标公示'
        classCode.value = '1001'
      } else {
        dropDownTitle.value = '中标公示'
        classCode.value = '1002'
      }
    })

    const toggle = (val: Idic) => {
      const { title, key } = val
      page.value = 1
      if (['招标公示', '中标公示'].includes(title)) {
        dropDownTitle.value = title
      }
      if (areaList.value.some((item) => item.title === title)) {
        region.value = key
      }
      if (industryList.value.some((item) => item.title === title)) {
        sort.value = key
      }
      if (typeList.value.some((item) => item.title === title)) {
        classCode.value = key
      }
      if (releaseTimeList.value.some((item) => item.title === title)) {
        releaseDateType.value = key
      }
      getLatestBid()
    }
    onMounted(() => {
      areaList.value = dictioary.areaList
      industryList.value = dictioary.industryList
      typeList.value = dictioary.typeList
      releaseTimeList.value = dictioary.releaseTimeList
      radioList.value = dictioary.tenderPublicList
      emitter.on('bidList', (data: any) => {
        bidList.value = data.rows
        total.value = data.total
      })
      if (RefAll.value) {
        scrollH.value = parentCb(RefAll.value, 0) as number
      }
      // getLatestBid()
    })
    return {
      allList,
      defaultIndex,
      line,
      dropDownTitle,
      areaList,
      industryList,
      typeList,
      releaseTimeList,
      toggle,
      RefType,
      bidList,
      pageSize,
      total,
      search,
      RefAll,
      radioList,
      change,
      page,
      classCode
    }
  }
})
