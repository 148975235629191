<template>
  <ul>
    <template v-if="bidList.length > 0">
      <li
        v-for="(item, index) in bidList"
        :key="index"
        class="flex_col pointer"
        @click="toDetail(item.uid)"
      >
        <div class="head flex">
          <span v-show="item.is_on_bidding === 1">招标中</span>
          <div class="title">{{ item.title }}</div>
          <div class="time flex">
            <img src="~assets/images/bidList/clock.png" alt="" />
            <span>{{ item.release_date }}</span>
          </div>
        </div>
        <div class="tips flex">
          <span v-show="item.region_name">{{ item.region_name }}</span>
          <span v-show="item.sort">{{ item.sort }}</span>
          <span v-show="item.class_name">{{ item.class_name }}</span>
        </div>
        <div class="line" />
      </li>
    </template>
    <template v-else>
      <li style="color: #909399; text-align: center; line-height: 20px">
        暂无数据
      </li>
    </template>
  </ul>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    bidList: {
      default: () => [],
      type: Array
    }
  },
  setup () {
    const router = useRouter()
    const toDetail = (uid: string) => {
      router.push({ path: '/bidDetail', query: { uid } })
    }
    return {
      toDetail
    }
  }
})
</script>
<style lang='scss' scoped>
li {
  padding-top: 33px;
  .head {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    align-items: center;
    span {
      color: #ff5b60;
      margin-right: 8px;
    }
    .title {
      max-width: 627px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 11px;
        height: 11px;
        margin-right: 6px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #bbbbbb;
      }
    }
  }
  .tips {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #849aae;
    span:not(:last-of-type) {
      margin-right: 10px;
    }
    span {
      background: #f2f5f7;
      padding: 7px 10px;
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  margin-top: 34px;
}
</style>
