<template>
  <div id="dropDown">
    <el-dropdown @visible-change="isShowDrop" @command="handleCommand">
      <span class="el-dropdown-link">
        <span :class="{ 'el-dropdown-link-title': true, active: showDrop }">{{
          pickUpItem ? pickUpItem : basePickUpItem
        }}</span>
        <i
          ref="refIcon"
          :class="{ 'el-icon-arrow-down': true, active: showDrop }"
        />
      </span>
      <template #dropdown>
        <el-dropdown-menu style="max-height:259px;overflow-y:scroll;overflow-x:hidden">
          <template v-if="datalist.length > 0">
            <el-dropdown-item v-for="(item, index) in datalist" :key="index" :command="item">{{
              item[value]
            }}</el-dropdown-item>
          </template>
          <template v-else>
            <el-dropdown-item>暂无内容</el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang='ts'>
import { defineComponent, PropType, ref } from 'vue'
import { IareaList, IlabelList } from '@/types/policyList/index'
import { Idic } from '@/types/bidList/index'

export default defineComponent({
  props: {
    datalist: {
      type: Array as PropType<IareaList[] | IlabelList[] | Idic[] | null>
    },
    // 默认选中
    basePickUpItem: {
      type: String
    },
    // 使用的key-value值
    keyValue: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  emits: ['toggle'],
  setup (props, context) {
    const showDrop = ref(false) // 当前是否显示下拉框
    const pickUpItem = ref<string>('') // 当前选中项
    const refIcon = ref<null | HTMLElement>(null)
    const isShowDrop = (cb: boolean) => {
      showDrop.value = cb
      if (cb === true && refIcon.value) {
        refIcon.value.style.transform = 'rotate(-180deg)'
        refIcon.value.style.transition = 'all 0.3s ease'
      } else if (cb === false && refIcon.value) {
        refIcon.value.style.transform = 'rotate(0)'
        refIcon.value.style.transition = 'all 0.3s ease'
      }
    }
    const handleCommand = (command: IareaList | IlabelList | Idic) => {
      pickUpItem.value = command[props.value]
      context.emit('toggle', command)
    }
    return {
      showDrop,
      isShowDrop,
      refIcon,
      pickUpItem,
      handleCommand
    }
  }
})

</script>
<style lang='scss' scoped>
.el-dropdown-link {
  padding: 0 25px;
  cursor: pointer;
  display: flex;
  width: 197px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 6px 48px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  align-items: center;
  .el-dropdown-link-title {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    flex: 1;
  }
  i {
    font-weight: bold;
    color: #595959;
  }
}
.active {
  color: #3c89ff !important;
}
:deep(.el-dropdown__popper) {
  max-height: 259px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
:deep(.el-dropdown-menu__item) {
  width: 197px;
  height: 37px;
  line-height: 37px;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(153, 153, 153, 0.45);
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(153, 153, 153, 0.45);
}
</style>
