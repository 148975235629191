<template>
 <div class="bidContent base_width flex">
   <div class="bidContent_left">
     <BidListHeadContentLatest/>
     <BidListHeadContentAll/>
   </div>
   <div class="bidContent_right">
     <img src="@/assets/images/bidList/bidListContent.jpg" alt="">
   </div>
 </div>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import BidListHeadContentLatest from './BidListContentLatest.vue'
import BidListHeadContentAll from './BidListContentAll.vue'

export default defineComponent({
  components: {
    BidListHeadContentLatest,
    BidListHeadContentAll
  }
})
</script>
<style lang='scss' scoped>
.bidContent {
  margin: auto;
  .bidContent_left {
    min-width: 860px;
  }
  .bidContent_right {
    margin-left: 62px;
    flex: 1;
  }
}
</style>
