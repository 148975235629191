
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    bidList: {
      default: () => [],
      type: Array
    }
  },
  setup () {
    const router = useRouter()
    const toDetail = (uid: string) => {
      router.push({ path: '/bidDetail', query: { uid } })
    }
    return {
      toDetail
    }
  }
})
