<template>
  <div id="bidList" class="base_min_width">
    <div class="bidListHead base_width">
      <div class="bidListHeadContent flex_col">
        <span>招标信息实时推送&nbsp;&nbsp;&nbsp;&nbsp;随时查询标讯</span>
        <span>慧点招标&nbsp;&nbsp;信息实时推送</span>
      </div>
    </div>
  </div>
  <main class="base_min_width">
    <BidListContent />
  </main>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
import BidListContent from './components/BidListContent.vue'

export default defineComponent({
  components: {
    BidListContent
  }
})
</script>
<style lang='scss' scoped>
#bidList {
  width: 100%;
  height: 517px;
  background: url(~assets/images/bidList/bidList_bg.jpg) no-repeat center center;
  background-size: cover;
  padding-top: 103px;
  .bidListHead {
    margin: auto;
    padding-top: 100px;
    .bidListHeadContent {
      & > span:first-of-type {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
      }
      & > span:last-of-type {
        font-size: 56px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
main {
  width: 100%;
  margin-top: 64px;
}
</style>
